export const routes = {
  login: '/login',
  dashboard: '/',
  users: '/users',
  admins: '/admin-users',
  userDetails: '/user-details/:id',
  serviceRequests: '/service-requests',
  categories: '/service-categories',
  subCategories: '/service-sub-categories',
  services: '/services',
  reports: '/reports',
  countries: '/countries',
  serviceProviders: '/service-providers',
}