import React from 'react';
import { Alert } from 'react-bootstrap';
import './styles.css'

export default function NotFound() {
  return (
    <div className='not-found'>
      <Alert variant='warning'>There are no record found</Alert>
    </div>
  );
}
