import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { MdAddCircle, MdClose } from 'react-icons/md';
import ServiceCategoryAPI from '../../../api/serviceCategory';
import { isEmpty } from 'lodash';
import { categoryMapper } from '../../../utils/selectMapper';
import Select from 'react-select';
import DataLoading from '../../common/DataLoading';
import SpinnerButton from '../../common/SpinnerButton';
import ErrorMessage from '../../common/ErrorMessage';
import UploadAPI from '../../../api/upload';

export default class AddSubCategory extends DataLoading {
  state = {
    ...this.state,
    category_code: '',
    proficiency: '',
    proficiencies: [],
    subcategory_name: '',
    description: '',
    categories: [],
    icon: '',
    imageFile: '',
    category: {},
  };

  loadserviceCategories = async () => {
    try {
      const api = new ServiceCategoryAPI();
      this.toggleLoading(true);
      const { error, payload } = await api.listCategories();
      if (error) {
        this.toggleLoading(false);
        return this.handleError(error);
      }
      this.toggleLoading(false);
      let { service_categories: categories } = payload;
      if (!isEmpty(categories)) {
        categories = categoryMapper(categories);
      }
      this.setState({
        categories,
      });
    } catch (error) {
      this.toggleLoading(false);
      this.handleError(error);
    }
  };
  async componentDidMount() {
    await this.loadserviceCategories();
  }

  imageUpload = async (file, subcategory_code) => {
    try {
      const api = new UploadAPI();
      const fd = new FormData();
      fd.append('image', file, file.name);
      fd.append('subcategory_code', subcategory_code);
      const { error } = await api.subCategoryUpload(fd);
      if (error) {
        this.handleError(error);
      }
    } catch (error) {
      this.handleError(error);
    }
  };

  onChange = async ({ target }) => {
    const { name, value } = target;
    if (!isEmpty(target.files)) {
      this.setState({
        icon: target.files[0].name,
        imageFile: target.files[0],
      });
    }
    this.setState({
      [name]: value,
    });
  };

  handleAddProficieny = () => {
    const { proficiency } = this.state;
    this.setState(prevState => ({
      proficiencies: [...prevState.proficiencies, proficiency],
      proficiency: '',
    }));
  };

  handleDeleteProficieny = proficiency => {
    const { proficiencies } = this.state;
    this.setState({
      proficiencies: proficiencies.filter(p => p !== proficiency),
    });
  };

  handleCategoryChange = category => {
    this.setState({ category, category_code: category.value });
  };

  onAddSubCategory = async () => {
    const {
      category_code,
      description,
      proficiencies,
      subcategory_name,
      imageFile,
    } = this.state;
    const data = {
      category_code,
      proficiency: [...proficiencies],
      subcategory_code: subcategory_name,
      subcategory_name,
      description,
      image_url: '/images/placeholder.jpg',
    };
    try {
      const api = new ServiceCategoryAPI();
      this.toggleWorking(true);
      const { error } = await api.addSubCategory({
        action: 'get',
        command: 'add_service_sub_category',
        data,
      });
      if (error) this.handleError(error);
      this.toggleWorking(false);
      await this.imageUpload(imageFile, subcategory_name);
      this.props.onHide();
      this.props.refresh();
    } catch (error) {
      this.handleError(error);
    }
  };

  render() {
    const { show, onHide } = this.props;
    const {
      categories,
      proficiency,
      proficiencies,
      description,
      subcategory_name,
      icon,
      category,
      working,
      error,
    } = this.state;
    return (
      <Modal show={show} onHide={onHide} centered size='md'>
        <Modal.Header closeButton>Add Sub Category</Modal.Header>
        <Modal.Body>
          {error && <ErrorMessage message={error} />}
          <Form.Group as={Col}>
            <Form.Label>Sub Category Name</Form.Label>
            <Form.Control
              name='subcategory_name'
              value={subcategory_name}
              onChange={this.onChange}
              placeholder='Sub Category Name'
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Proficiency</Form.Label>
            <Row>
              <Col md={10}>
                <Form.Control
                  name='proficiency'
                  value={proficiency}
                  onChange={this.onChange}
                  placeholder='Proficiency'
                />
              </Col>
              <Col md={2}>
                <Button
                  onClick={this.handleAddProficieny}
                  className='btn-primary'
                  disabled={!proficiency}
                >
                  Add
                </Button>
              </Col>
              <Col md={12}>
                <div className='proficiency-container mt-2'>
                  {!isEmpty(proficiencies) &&
                    proficiencies.map((p, idx) => (
                      <span key={idx} className='proficiency-tag'>
                        {p}{' '}
                        <MdClose
                          onClick={() => this.handleDeleteProficieny(p)}
                        />
                      </span>
                    ))}
                </div>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Icon</Form.Label>
            <Form.File
              id='custom-file-translate-html'
              custom
              label={icon || 'select file'}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Select Category</Form.Label>
            <Select
              options={categories}
              value={category}
              placeholder='Select Category'
              onChange={this.handleCategoryChange}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Description</Form.Label>
            <Form.Control
              name='description'
              value={description}
              onChange={this.onChange}
              placeholder='Description'
              as='textarea'
            />
          </Form.Group>

          <Col lg={12} className='add-button-wrapper'>
            <Button className='btn-secondary mr-3' onClick={onHide}>
              <MdClose /> Close
            </Button>
            <SpinnerButton
              working={working}
              disabled={working}
              className='btn-primary'
              onClick={this.onAddSubCategory}
            >
              <MdAddCircle /> Add Sub Category
            </SpinnerButton>
          </Col>
        </Modal.Body>
      </Modal>
    );
  }
}
