import React from 'react';
import { Col } from 'react-bootstrap';
import classNames from 'classnames';
import AvatarComponent from '../common/Avatar';
import { history } from '../../utils/history';
import ActionButtonComponent from '../common/ActionButton';

export default function RequestCard({ request, onRejectRequest, onConfirmRequest }) {
  const {
    status,
    subcategory_code,
    request_date,
    client,
    business_service_name,
    service_address,
    service_description,
    clientId,
    service_request_id,
    spid,
  } = request;

  return (
    <>
      <Col
        sm={2}
        className='service-item'
        onClick={() => history.push(`/user-details/${spid}`)}
      >
        <AvatarComponent
          className='mr-3'
          name={`${business_service_name.charAt(0)}
          ${business_service_name.charAt(1)}`}
        />
        {business_service_name}
      </Col>
      <Col sm={2} className={classNames('service-item', 'service-description')}>
        {service_description}
      </Col>
      <Col sm={2} className='service-item'>
        {service_address}
      </Col>
      <Col sm={2} className='service-item'>
        {subcategory_code}
      </Col>
      <Col
        sm={1}
        className='service-item'
        role='button'
        onClick={() => history.push(`/user-details/${clientId}`)}
      >
        {client}
      </Col>
      <Col sm={1} className='service-item'>
        {request_date}
      </Col>
      <Col sm={1} className='service-item'>
        {status}
      </Col>
      <Col sm={1} className='service-item'>
        <ActionButtonComponent
          type='request'
          requestConfirmed={status.toUpperCase() === 'CONFIRMED'}
          onConfirmRequest={() => onConfirmRequest(service_request_id)}
          onRejectRequest={() => onRejectRequest(service_request_id)}
        />
      </Col>
    </>
  );
}
