import { Divider } from '@material-ui/core';
import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import DataLoading from '../common/DataLoading';
import ExportCSV from '../common/ExportCSV';
import Loader from '../common/Loader';
import './styles.css';
import SubCategoryCard from './components/SubCategoryCard';
import moment from 'moment';
import { isEmpty, orderBy } from 'lodash';
import ServiceCategoryAPI from '../../api/serviceCategory';
import {
  MdAdd,
  MdFileDownload,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import AddSubCategory from './components/AddSubCategory';
import ConfirmModal from '../common/ConfirmModal';

export default class SubCategories extends DataLoading {
  exportToCsvLink = React.createRef();
  state = {
    ...super.state,
    subCategories: [],
    searchTerm: '',
    ascending: true,
    message: '',
    category: {},
    categoryModal: false,
    show: false,
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  };


  onToggleCategoryModal = category => {
    this.setState(prevState => ({
      categoryModal: !prevState.categoryModal,
      category,
      message:  category.status.toLowerCase() === 'active'
      ? 'Are you sure you want to deactivate this sub category?'
      : 'Are you sure you want to deactivate this sub category?',
    }));
  };

  reset = () => {
    this.setState({
      show: false,
      message: '',
      category: {},
      categoryModal: false,
    });
  };

  onToggleCategory = async () => {
    const { subcategory_code, status } = this.state.category;
    console.log(this.state.category);
    try {
      this.toggleWorking(true);
      const api = new ServiceCategoryAPI();
      const { error } = await api.updateSubCategory(
        { status: status.toLowerCase() === 'active' ? 'INACTIVE' : 'ACTIVE' },
        subcategory_code
      );
      if (error) {
        this.toggleWorking(false);
        return this.handleError(error);
      }
      await this.loadserviceCategories();
      this.toggleWorking(false);
      this.reset();
    } catch (error) {
      this.toggleWorking(false);
      this.handleError(error);
    }
  };

  loadsubCategories = async () => {
    try {
      const api = new ServiceCategoryAPI();
      this.toggleLoading(true);
      const { error, payload } = await api.listSubCategories();
      if (error) {
        this.toggleLoading(false);
        return this.handleError(error);
      }
      this.toggleLoading(false);
      let { service_sub_categories: subCategories } = payload;
      if (!isEmpty(subCategories)) {
        subCategories = subCategories.map(serviceProvider => {
          const { created_date } = serviceProvider;
          serviceProvider.created_date = moment(created_date).format(
            'DD/MM/YYYY'
          );
          return serviceProvider;
        });
      }
      this.setState({
        subCategories,
      });
      this.onSort();
    } catch (error) {
      this.toggleLoading(false);
      this.handleError(error);
    }
  };
  async componentDidMount() {
    await this.loadsubCategories();
  }
  onExportToCsv = () => {
    this.exportToCsvLink.link.click();
  };

  csvHeaders = () => {
    return [
      { label: 'Sub Category Name', key: 'subcategory_name' },
      { label: 'Sub Category Code', key: 'subcategory_code' },
      { label: 'Number on Services', key: 'num_of_services' },
      { label: 'Description', key: 'description' },
      { label: 'Status', key: 'status' },
      { label: 'Date Created', key: 'created_date' },
    ];
  };

  onSearchServiceCategory = async () => {
    let { searchTerm, subCategories } = this.state;
    if (searchTerm) {
      subCategories = subCategories.filter(serviceProvider =>
        serviceProvider.names.toLowerCase().includes(searchTerm.toLowerCase())
      );
      this.setState({ subCategories });
    } else {
      await this.loadsubCategories();
    }
  };

  onToggleModal = () => {
    this.setState(prevState => ({
      show: !prevState.show,
    }));
  };

  onSort = () => {
    let { subCategories, ascending } = this.state;
    subCategories = orderBy(
      subCategories,
      ['subcategory_name'],
      [ascending ? 'asc' : 'desc']
    );
    this.setState({ subCategories, ascending: !this.state.ascending });
  };

  render() {
    const {
      loading,
      subCategories,
      searchTerm,
      show,
      ascending,
      categoryModal,
      message,
      working,
    } = this.state;
    return (
      <>
        <ConfirmModal
          show={categoryModal}
          title='Warning'
          message={message}
          onHide={this.reset}
          working={working}
          onConfirm={this.onToggleCategory}
        />
        <AddSubCategory
          refresh={this.loadsubCategories}
          show={show}
          onHide={this.onToggleModal}
        />
        <div className='user-list-container'>
          <div className='user-list-header mb-3'>
            <div className='user-filter-container'>
              <Button
                className='btn-secondary mr-3'
                onClick={this.onToggleModal}
              >
                <MdAdd /> Add Sub Category
              </Button>
              {subCategories.length > 0 && (
                <Button className='btn-primary' onClick={this.onExportToCsv}>
                  <MdFileDownload /> Export to CSV
                </Button>
              )}
            </div>
            <div className='user-search'>
              <Form.Control
                value={searchTerm}
                onChange={this.onChange}
                name='searchTerm'
                className='search-field'
                placeholder='Search service category'
              />
              <Button
                className='btn-primary'
                onClick={this.onSearchServiceCategory}
              >
                Search
              </Button>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className='user-list-wrapper'>
              <ExportCSV
                headers={this.csvHeaders()}
                fileName='service_sub_categories.csv'
                ref={ref => (this.exportToCsvLink = ref)}
                data={subCategories}
              />
              {!isEmpty(subCategories) ? (
                <div>
                  <Row className='user-list-title-header'>
                    <Col sm={2} onClick={this.onSort} className='sort-item'>
                      Sub Category Name{' '}
                      {ascending ? (
                        <MdKeyboardArrowUp />
                      ) : (
                        <MdKeyboardArrowDown />
                      )}
                    </Col>
                    <Col sm={1}>Category Code</Col>
                    <Col sm={1}>Number of Services</Col>
                    <Col sm={1}>Country</Col>
                    <Col sm={2}>Description</Col>
                    <Col sm={2}>Proficiency</Col>
                    <Col sm={1}>Date Created</Col>
                    <Col sm={1}>Status</Col>
                    <Col sm={1}></Col>
                  </Row>
                  <Divider className=' divider mt-3' />
                  <div className='category-list'>
                    {subCategories.map(category => (
                      <Row key={category._id} className='user-card mb-2'>
                        <SubCategoryCard category={category}  onToggleCategory={this.onToggleCategoryModal} />
                      </Row>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </>
    );
  }
}
