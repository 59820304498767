import { processRequest } from '../utils/processRequest';
import APIBase from './base';

export default class UploadAPI extends APIBase {
 

  subCategoryUpload = (file) => {
    let request = this.uploadAPI.post('/subcategory', file);
    return processRequest(request);
  };

  categoryUpload = (file) => {
    let request = this.uploadAPI.post('/category', file);
    return processRequest(request);
  };
  countryUpload = (file) => {
    let request = this.uploadAPI.post('/country', file);
    return processRequest(request);
  };
}
