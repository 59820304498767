import { processRequest } from '../utils/processRequest';
import APIBase from './base';

export default class ServiceRequestAPI extends APIBase {
 
  listRequests = () => {
    const request = this.api.get('/service_requests');
    return processRequest(request);
  };

  getServiceRequestSummary = () => {
    const request = this.api.get('/service_request_summary');
    return processRequest(request);
  }
  updateRequest = (payload) => {
    const body = payload
    let request = this.mobileAPI.post('/', body);
    return processRequest(request);
  }
}
