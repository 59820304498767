import { Divider } from '@material-ui/core';
import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import DataLoading from '../common/DataLoading';
import ExportCSV from '../common/ExportCSV';
import Loader from '../common/Loader';
import './styles.css';
import ServiceCategoryCard from './components/ServiceCategoryCard';
import moment from 'moment';
import { isEmpty, orderBy } from 'lodash';
import ServiceCategoryAPI from '../../api/serviceCategory';
import AddCategory from './components/AddCategory';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import NotFound from '../common/NotFound';
import ConfirmModal from '../common/ConfirmModal';

export default class ServiceCategories extends DataLoading {
  exportToCsvLink = React.createRef();
  state = {
    ...super.state,
    serviceCategories: [],
    searchTerm: '',
    show: false,
    message: '',
    category: {},
    categoryModal: false,
    lastCategory: null,
    ascending: true,
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  };

  onToggleModal = () => {
    this.setState(prevState => ({
      show: !prevState.show,
    }));
  };

  loadserviceCategories = async () => {
    try {
      const api = new ServiceCategoryAPI();
      this.toggleLoading(true);
      const { error, payload } = await api.listCategories();
      if (error) {
        this.toggleLoading(false);
        return this.handleError(error);
      }
      this.toggleLoading(false);
      let { service_categories: serviceCategories } = payload;
      if (!isEmpty(serviceCategories)) {
        serviceCategories = serviceCategories.map(serviceProvider => {
          const { created_date } = serviceProvider;
          serviceProvider.created_date = moment(created_date).format(
            'DD/MM/YYYY'
          );
          return serviceProvider;
        });
      }
      this.setState({
        serviceCategories,
        lastCategory: serviceCategories[serviceCategories.length - 1],
      });
      this.onSort();
    } catch (error) {
      this.toggleLoading(false);
      this.handleError(error);
    }
  };
  async componentDidMount() {
    await this.loadserviceCategories();
  }
  onExportToCsv = () => {
    this.exportToCsvLink.link.click();
  };

  csvHeaders = () => {
    return [
      { label: 'Category Name', key: 'category_name' },
      { label: 'Category Code', key: 'category_code' },
      { label: 'Number on Services', key: 'num_of_services' },
      { label: 'Description', key: 'description' },
      { label: 'Status', key: 'status' },
      { label: 'Date Created', key: 'created_date' },
    ];
  };

  onToggleCategoryModal = category => {
    this.setState(prevState => ({
      categoryModal: !prevState.categoryModal,
      category,
      message:  category.status.toLowerCase() === 'active'
      ? 'Are you sure you want to deactivate this category?'
      : 'Are you sure you want to deactivate this category?',
    }));
  };

  reset = () => {
    this.setState({
      show: false,
      message: '',
      category: {},
      categoryModal: false,
    })
  }

  onToggleCategory = async () => {
    const { category_code, status } = this.state.category;
    try {
      this.toggleWorking(true);
      const api = new ServiceCategoryAPI();
      const { error } = await api.updateCategory(
        { status: status.toLowerCase() === 'active' ? 'INACTIVE' : 'ACTIVE' },
        category_code
      );
      if (error) {
        this.toggleWorking(false);
        return this.handleError(error);
      }
      await this.loadserviceCategories();
      this.toggleWorking(false);
    } catch (error) {
      this.toggleWorking(false);
      this.handleError(error);
    }
  };

  onSearchServiceCategory = async () => {
    let { searchTerm, serviceCategories } = this.state;
    if (searchTerm) {
      serviceCategories = serviceCategories.filter(serviceProvider =>
        serviceProvider.names.toLowerCase().includes(searchTerm.toLowerCase())
      );
      this.setState({ serviceCategories });
    } else {
      await this.loadserviceCategories();
    }
  };

  onSort = () => {
    let { serviceCategories, ascending } = this.state;
    serviceCategories = orderBy(
      serviceCategories,
      ['category_name'],
      [ascending ? 'asc' : 'desc']
    );
    this.setState({ serviceCategories, ascending: !this.state.ascending });
  };

  render() {
    const {
      loading,
      serviceCategories,
      searchTerm,
      show,
      lastCategory,
      categoryModal,
      message,
      working,
      ascending,
    } = this.state;
    return (
      <>
        <AddCategory
          show={show}
          onHide={this.onToggleModal}
          refresh={this.loadserviceCategories}
          lastCategory={lastCategory}
        />

        <ConfirmModal
          show={categoryModal}
          title='Warning'
          message={
           message
          }
          onHide={this.reset}
          working={working}
          onConfirm={this.onToggleCategory}
        />

        <div className='user-list-container'>
          <div className='user-list-header mb-3'>
            <div className='user-filter-container'>
              <Button
                className='btn-secondary mr-3'
                onClick={this.onToggleModal}
              >
                Add Category
              </Button>
              {serviceCategories.length > 0 && (
                <Button className='btn-primary' onClick={this.onExportToCsv}>
                  Export to CSV
                </Button>
              )}
            </div>
            <div className='user-search'>
              <Form.Control
                value={searchTerm}
                onChange={this.onChange}
                name='searchTerm'
                className='search-field'
                placeholder='Search service category'
              />
              <Button
                className='btn-primary'
                onClick={this.onSearchServiceCategory}
              >
                Search
              </Button>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className='user-list-wrapper'>
              <ExportCSV
                headers={this.csvHeaders()}
                fileName='service_categories.csv'
                ref={ref => (this.exportToCsvLink = ref)}
                data={serviceCategories}
              />
              {!isEmpty(serviceCategories) ? (
                <div>
                  <Row className='user-list-title-header mt-3'>
                    <Col sm={3} onClick={this.onSort} className='sort-item'>
                      Category Name{' '}
                      {ascending ? (
                        <MdKeyboardArrowUp />
                      ) : (
                        <MdKeyboardArrowDown />
                      )}
                    </Col>
                    <Col sm={1}>Category Code</Col>
                    <Col sm={1}>Number of Services</Col>
                    <Col sm={1}>Country</Col>
                    <Col sm={3}>Description</Col>
                    <Col sm={1}>Date Created</Col>
                    <Col sm={1}>Status</Col>
                    <Col sm={1}></Col>
                  </Row>
                  <Divider className=' divider mt-3' />
                  <div className='category-list'>
                    {serviceCategories.map(category => (
                      <Row key={category._id} className='user-card mb-2'>
                        <ServiceCategoryCard
                          category={category}
                          onToggleCategory={this.onToggleCategoryModal}
                        />
                      </Row>
                    ))}
                  </div>
                </div>
              ) : (
                <NotFound />
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}
