import React from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { MdAddCircle, MdClose } from 'react-icons/md';
import ServiceCategoryAPI from '../../../api/serviceCategory';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import DataLoading from '../../common/DataLoading';
import SpinnerButton from '../../common/SpinnerButton';
import ErrorMessage from '../../common/ErrorMessage';
import UploadAPI from '../../../api/upload';
import CountryAPI from '../../../api/country';

export default class AddCategory extends DataLoading {
  state = {
    ...this.state,
    proficiency: '',
    category_name: '',
    description: '',
    countries: [],
    icon: '',
    imageFile: '',
    country_code: {},
  };

  loadCountries = async () => {
    try {
      const api = new CountryAPI();
      this.toggleLoading(true);
      let { error, payload: countries } = await api.listCountries();
      if (error) {
        return this.handleError(error);
      }
      this.toggleLoading(false);
      if (!isEmpty(countries)) {
       countries = countries.map(country => {
         return {
           label: country.country_name,
           value: country.country_code,
         }
       })
      }
      this.setState({
        countries,
      });
    } catch (error) {
      this.handleError(error);
    }
  };
  async componentDidMount() {
    await this.loadCountries();
  }

  imageUpload = async (file, category_code) => {
    try {
      const api = new UploadAPI();
      const fd = new FormData();
      fd.append('image', file, file.name);
      fd.append('category_code', category_code);
      const { error } = await api.categoryUpload(fd);
      if (error) {
        this.handleError(error);
      }
    } catch (error) {
      this.handleError(error);
    }
  };

  onChange = async ({ target }) => {
    const { name, value } = target;
    if (!isEmpty(target.files)) {
      this.setState({
        icon: target.files[0].name,
        imageFile: target.files[0],
      });
    }
    this.setState({
      [name]: value,
    });
  };

  handleCountryChange = country_code => {
    this.setState({ country_code: country_code.value });
  };

  onAddCategory = async () => {
    const {lastCategory} = this.props;
    
    const {
      description,
      category_name,
      imageFile,
      country_code,
    } = this.state;

    let code = lastCategory.category_code;
    if(typeof parseInt(code) === 'number' && !isNaN(parseInt(code))) {
      code = (parseInt(code) + 1).toString();
      if(code.length <3) {
        code = `0${code}`
      }
    }else{
      code = "001"
    }

    const data = {
      category_code: code,
      category_name,
      description,
      image_url: '/images/placeholder.jpg',
      country_code,
      created_by: "ADMIN",
    };
    try {
      const api = new ServiceCategoryAPI();
      this.toggleWorking(true);
      const { error } = await api.addCategory({
        action: 'get',
        command: 'add_service_category',
        data,
      });
      if (error) this.handleError(error);
      this.toggleWorking(false);
      await this.imageUpload(imageFile, code);
      this.props.onHide();
      this.props.refresh();
    } catch (error) {
      this.handleError(error);
    }
  };

  render() {
    const { show, onHide } = this.props;
    const {
      countries,
      description,
      category_name,
      icon,
      working,
      error,
    } = this.state;
    
    return (
      <Modal show={show} onHide={onHide} centered size='md'>
        <Modal.Header closeButton>Add Category</Modal.Header>
        <Modal.Body>
          {error && <ErrorMessage message={error} />}
          <Form.Group as={Col}>
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              name='category_name'
              value={category_name}
              onChange={this.onChange}
              placeholder='Category Name'
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Icon</Form.Label>
            <Form.File
              custom
              label={icon || 'select file'}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Select Category</Form.Label>
            <Select
              options={countries}
              placeholder='Select Country'
              onChange={this.handleCountryChange}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Description</Form.Label>
            <Form.Control
              name='description'
              value={description}
              onChange={this.onChange}
              placeholder='Description'
              as='textarea'
            />
          </Form.Group>

          <Col lg={12} className='add-button-wrapper'>
            <Button className='btn-secondary mr-3' onClick={onHide}>
              <MdClose /> Close
            </Button>
            <SpinnerButton
              working={working}
              disabled={working}
              className='btn-primary'
              onClick={this.onAddCategory}
            >
              <MdAddCircle /> Add Category
            </SpinnerButton>
          </Col>
        </Modal.Body>
      </Modal>
    );
  }
}
