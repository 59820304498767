import React from 'react';
import { BsList } from 'react-icons/bs';
import './styles.css';
import { MdPerson } from 'react-icons/md';
import { FiLogOut } from 'react-icons/fi';
import DropdownComponent from '../common/Dropdown';
import { DropdownItem } from 'reactstrap';
import AuthAPI from '../../api/auth';

export default function Header({ collapse, onCollapse }) {
  let title = (
    <>
      <MdPerson className="header-icon" /> {localStorage.getItem('username')}
    </>
  );
  const api = new AuthAPI()
  return (
    <div className='header'>
      <div className='header-content'>
        <div>
          {collapse && (
            <BsList
              className='brand-icon'
              onClick={() => onCollapse(!collapse)}
            />
          )}
          Dashboard
        </div>
        <div className='header-account'>
          <DropdownComponent title={title} showCaret={true}>
            <DropdownItem onClick={api.logout} className='header-item'>
              <FiLogOut /> Logout
            </DropdownItem>
          </DropdownComponent>
        </div>
      </div>
    </div>
  );
}
