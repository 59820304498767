import React from 'react';
import { CSVLink } from 'react-csv';

const ExportCSV = React.forwardRef(
  ({ data, headers, separator = ',', fileName = 'download.csv' }, ref) => {
    return (
      <CSVLink
        data={data}
        ref={ref}
        headers={headers}
        separator={separator}
        filename={fileName}
      />
    );
  }
);

export default ExportCSV;
