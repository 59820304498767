import React from 'react';
import classNames from 'classnames';
import './styles.css'
import { Avatar } from '@material-ui/core';

const baseImageUrl = process.env.REACT_APP_IMAGE_URL
export default function AvatarComponent({ className, name, imageUrl }) {
  const imgUrl = `${baseImageUrl}${imageUrl}`
  return <Avatar className={classNames('avatar', className)} alt={name} src={imgUrl} />
}
