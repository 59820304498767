import {  processRequest } from '../utils/processRequest';
import { history } from '../utils/history';
import { routes } from '../routes';
import axios from 'axios';
import { baseURL } from '../config';


export default class AuthAPI {
  constructor() {
    this.api = axios.create({
      baseURL,
    });
  }

  login = ({ username, password }) => {
    let request = this.api.post('/login', { username, password });
    return processRequest(request);
  };

  logout = () => {
    localStorage.clear();
    history.push(routes.login);
  };

  resetPassword = (user_id) => {
    let request = this.api.post('/user_pwd_reset', { user_id});
    return processRequest(request);
  }
}
