import React from 'react';
import { Col } from 'react-bootstrap';
import classNames from 'classnames';
import ActionButtonComponent from '../../common/ActionButton';
import AvatarComponent from '../../common/Avatar';
import { isEmpty } from 'lodash';

export default function SubCategoryCard({ category, onToggleCategory }) {
  const {
    num_of_services,
    status,
    proficiency,
    description,
    country_code,
    subcategory_name,
    category_code,
    image_url,
    created_date,
  } = category;
  return (
    <>
      <Col sm={2} className='user-name' role='button'>
        <AvatarComponent
          className='user-avatar'
          name={subcategory_name.charAt(0)}
          imageUrl={image_url}
        />
        {subcategory_name}
      </Col>
      <Col className='user-name' sm={1}>
        {category_code}
      </Col>
      <Col className='user-name' sm={1}>
        {num_of_services}
      </Col>
      <Col className='user-name' sm={1}>
        {country_code}
      </Col>
      <Col className='user-name' sm={2}>
        {description}
      </Col>
      <Col className='user-name proficieny-container' sm={2}>
        {!isEmpty(proficiency)
          ? proficiency.map((p, idx) => (
              <>
                <span key={idx} className='proficieny-card'>
                  {p}
                </span>
              </>
            ))
          : null}
      </Col>
      <Col className='user-name' sm={1}>
        {created_date}
      </Col>
      <Col
        sm={1}
        className={classNames(
          'user-name',
          status.toLowerCase() === 'active' ? 'active' : 'inactive'
        )}
      >
        {status.toLowerCase() === 'active' ? 'Active' : 'Disabled'}
      </Col>
      <Col className='action-btn' sm={1}>
        <ActionButtonComponent
          type='category'
          onToggleCategory={() => onToggleCategory(category)}
          isCategoryActive={status.toLowerCase() === 'active'}
        />
      </Col>
    </>
  );
}
