import React from 'react';
import './styles.css';
import DataLoading from '../common/DataLoading';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { Divider } from '@material-ui/core';
import Loader from '../common/Loader';
import ErrorMessage from '../common/ErrorMessage';
import { isEmpty } from 'lodash';
import moment from 'moment';
import ServiceRequestAPI from '../../api/serviceRequest';
import RequestCard from './RequestCard';
import ExportCSV from '../common/ExportCSV';
import { MdClear, MdFilter } from 'react-icons/md';
import NotFound from '../common/NotFound';
import ConfirmModal from '../common/ConfirmModal';

const statuses = ['Confirmed', 'UnConfirmed', 'Rejected'];

export default class ServiceRequests extends DataLoading {
  state = {
    ...super.state,
    searchTerm: '',
    serviceRequests: [],
    startDate: '',
    endDate: '',
    showConfirm: false,
    showReject: false,
    requestId: '',
    status: '',
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  };

  onExportToCsv = () => {
    this.exportToCsvLink.link.click();
  };

  csvHeaders = () => {
    return [
      { label: 'Business Name', key: 'business_service_name' },
      { label: 'Service Description', key: 'service_description' },
      { label: 'Service Location', key: 'service_address' },
      { label: 'Sub Category', key: 'subcategory_code' },
      { label: 'Client', key: 'client' },
      { label: 'Date Requested', key: 'request_date' },
      { label: 'Status', key: 'status' },
    ];
  };

  onToggleModal = name => {
    this.setState(prevState => ({
      [name]: !prevState.name,
    }));
  };

  onRejectRequest = requestId => {
    this.setState({ requestId, showReject: true });
  };

  onConfirmRequest = requestId => {
    this.setState({ requestId, showConfirm: true });
  };

  handleRequestUpdate = async () => {
    try {
      this.toggleWorking(true);
      const api = new ServiceRequestAPI();
      const { showConfirm, requestId } = this.state;
      const payload = {
        action: 'get',
        command: showConfirm ? 'confirm_request' : 'reject_request',
        data: { service_request_id: requestId },
      };
      await api.updateRequest(payload);
      this.reset();
      this.toggleWorking(true);
      await this.loadServiceRequests();
    } catch (error) {
      this.toggleWorking(true);
      this.handleError(error);
    }
  };

  reset = () => {
    this.setState({
      showConfirm: false,
      requestId: false,
      showReject: false,
    });
  };

  loadServiceRequests = async () => {
    const api = new ServiceRequestAPI();
    const { startDate, endDate, status } = this.state;
    try {
      this.toggleLoading(true);
      let { error, payload } = await api.listRequests();
      if (error) {
        this.toggleLoading(false);
        return this.handleError(error);
      }
      let { service_requests } = payload;
      if (!isEmpty(service_requests)) {
        if (startDate && endDate) {
          service_requests = service_requests.filter(
            service =>
              (moment(service.request_date).isAfter(moment(startDate), 'minutes') ||
                moment(service.request_date).isSame(moment(startDate), 'day')) &&
              (moment(service.request_date).isBefore(moment(endDate), 'minutes') ||
                moment(service.request_date).isSame(moment(endDate), 'day'))
          );
        } else if (status) {
          service_requests = service_requests.filter(
            service => service.status.toUpperCase() === status.toUpperCase()
          );
        }
        service_requests.forEach(request => {
          request.request_date = moment(request.request_date).format(
            'DD/MM/YYYY'
          );
          request.client = request.client.names;
          request.clientId = request.user_id;
          request.business_service_name = request.service.business_service_name;
          request.service_address = request.service.service_address;
          request.service_description = request.service.service_description;
        });
      }
      this.setState({ serviceRequests: service_requests });
      this.toggleLoading(false);
    } catch (error) {
      this.toggleLoading(false);
      this.handleError(error);
    }
  };

  onClearFilter = () => {
    this.setState({ endDate: '', startDate: '', status: '' }, async () => {
      await this.loadServiceRequests();
    });
  };

  async componentDidMount() {
    const { location } = this.props;
    if (location.state) {
      await this.setState({ status: location.state.status });
    }
    await this.loadServiceRequests();
  }
  onSearchService = async () => {
    let { searchTerm, services } = this.state;
    if (searchTerm) {
      services = services.filter(service =>
        service.service_description
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      this.setState({ services });
    } else {
      await this.loadServiceRequests();
    }
  };
  render() {
    const {
      error,
      loading,
      serviceRequests: requests,
      searchTerm,
      startDate,
      status,
      showConfirm,
      showReject,
      endDate,
      working,
    } = this.state;
    return (
      <>
       <ConfirmModal
              show={showReject || showConfirm}
              title='Warning'
              message={showConfirm ? 'Are you sure you want to confirm this request?' : 'Are you sure you want to reject this request?'}
              onHide={this.reset}
              working={working}
              onConfirm={() => this.handleRequestUpdate()}
            />
        <div className='requests-wrapper'>
          <div className='requests-header mb-3'>
            <div className='request-filter-container'>
              {requests.length > 0 && (
                <Button className='btn-primary' onClick={this.onExportToCsv}>
                  Export to CSV
                </Button>
              )}
            </div>
            <div className='request-search'>
              <Form.Control
                value={searchTerm}
                onChange={this.onChange}
                name='searchTerm'
                className='search-field'
                placeholder='Search Service request'
              />
              <Button className='btn-primary' onClick={this.onSearchService}>
                Search
              </Button>
            </div>
          </div>
          <div className='requests-header mb-3'>
            <div className='request-search'>
              <h6>Start Date:</h6>
              <Form.Control
                className='date-input'
                type='date'
                onChange={this.onChange}
                name='startDate'
                value={startDate}
                placeholder='Start Date'
              />
              <h6>End Date:</h6>
              <Form.Control
                className='date-input'
                type='date'
                onChange={this.onChange}
                name='endDate'
                value={endDate}
                placeholder='End Date'
              />
              <h6>Status:</h6>
              <Form.Control
                as='select'
                className='select-field'
                placeholder='Select Status'
                name='status'
                value={status}
                onChange={this.onChange}
              >
                <option></option>
                {statuses.map(status => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Control>
              <Button
                className='btn-primary ml-3'
                onClick={this.loadServiceRequests}
              >
                <MdFilter /> Filter
              </Button>
              <Button className='btn-primary ml-3' onClick={this.onClearFilter}>
                <MdClear /> Clear Filter
              </Button>
            </div>
          </div>
          {error && <ErrorMessage message={error} />}
          {loading ? (
            <Loader />
          ) : (
            <div className='request-list-wrapper'>
              <ExportCSV
                headers={this.csvHeaders()}
                fileName='service_requests.csv'
                ref={ref => (this.exportToCsvLink = ref)}
                data={requests}
              />
              {requests.length > 0 ? (
                <>
                  <Row className='request-list-title-header mt-3'>
                    <Col sm={2}>Business Service Name</Col>
                    <Col sm={2}>Service Description</Col>
                    <Col sm={2}>Service Location</Col>
                    <Col sm={2}>Sub Category</Col>
                    <Col sm={1}>Client</Col>
                    <Col sm={1}>Requested On</Col>
                    <Col sm={1}>Status</Col>
                    <Col sm={1} />
                  </Row>
                  <Divider className='my-2' />
                  <div className='request-list'>
                    {requests.map((request, idx) => (
                      <Row className='request-card mb-2' key={idx}>
                        <RequestCard
                          request={request}
                          onRejectRequest={this.onRejectRequest}
                          onConfirmRequest={this.onConfirmRequest}
                        />
                      </Row>
                    ))}
                  </div>
                </>
              ) : (
                <NotFound />
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}
