import { routes } from '../routes';

import { history } from './history';

export const handleError = error => {
  if (typeof error === 'string') {
    return error;
  }
  if (error.error) {
    if(error.error === 'invalid token') {
      // @TODO create an interceptor
      localStorage.clear()
      history.push(routes.login)
    }
    if (error.error.code) {
      return error.error.message;
    }
  }
  return error.error;
};
