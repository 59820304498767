import { processRequest } from '../utils/processRequest';
import APIBase from './base';

export default class ServiceCategoryAPI extends APIBase {
  listCategories = () => {
    let request = this.api.get('/service_categories');
    return processRequest(request);
  };

  addCategory = (category) => {
    const body = category
    let request = this.mobileAPI.post('/', body);
    return processRequest(request);
  }

  updateCategory = (update, categoryCode) => {
    const body = update
    let request = this.api.put(`/service_category/${categoryCode}`, body);
    return processRequest(request);
  }

  getCategory = (categoryCode) => {
    let request = this.api.get(`/service_category/${categoryCode}`);
    return processRequest(request);
  }

  // sub-categories enpoints

  addSubCategory = (subCategory) => {
    const body = subCategory
    let request = this.mobileAPI.post('/', body);
    return processRequest(request);
  }
  updateSubCategory = (update, subCategoryCode) => {
    const body = update
    let request = this.api.put(`/service_sub_category/${subCategoryCode}`, body);
    return processRequest(request);
  }
  
  listSubCategories = () => {
    let request = this.api.get('/list_service_subcategories');
    return processRequest(request);
  }

  listSubCategoriesByCategoryCode = (categoryCode) => {
    let request = this.api.post(`/list_service_subcategories/${categoryCode}`);
    return processRequest(request);
  }
}
