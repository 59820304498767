

export const processRequest = async (request) => {
  try {
    const response = await request;
    if (response.data.result) {
      return {
        payload: response.data.result,
      };
    }
    return {
      error: { message: response.data.error },
    };
  } catch (error) {
    if (error.response) {
      return { error: error.response.data };
    } else {
      return {
        error: { message: 'Something went wrong, please try later!' },
      };
    }
  }
};


