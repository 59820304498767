import { processRequest } from '../utils/processRequest';
import APIBase from './base';

export default class CountryAPI extends APIBase {
  listCountries = () => {
    let request = this.api.get('/country');
    return processRequest(request);
  };

  getCountry = countryCode => {
    let request = this.api.get(`/country/${countryCode}`);
    return processRequest(request);
  };

  addCountry = data => {
    let request = this.mobileAPI.post('/', data);
    return processRequest(request);
  };
}
