import React, { useState } from 'react';
import {
  MdKeyboardArrowLeft,
  MdApps,
  MdMap,
  MdDashboard,
  MdNotificationsActive,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { Category, CategoryTwoTone } from '@material-ui/icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { FaUsers, FaUsersCog } from 'react-icons/fa';
import './styles.css';
import { routes } from '../../routes';

export default function SideNav({ onCollapse, collapse }) {
  const [open, setOpen] = useState(true);
  return (
    <div className={classNames(collapse ? 'collapse-side-nav' : 'side-nav')}>
      <div className='dashboard-title'>
        <div className='title-item'>
          <div className='title-header'>
            <MdApps className='title-icon' /> Kazi dashboard
          </div>
          <MdKeyboardArrowLeft
            className='toggle-icon'
            onClick={() => onCollapse(true)}
          />
        </div>
      </div>
      <List component='nav' className='navigation'>
        <ListItem
          component={Link}
          to={routes.dashboard}
          button
          className='nav-item'
        >
          <ListItemIcon>
            <MdDashboard className='nav-icon' />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </ListItem>
        <ListItem
          component={Link}
          to={routes.users}
          button
          className='nav-item'
        >
          <ListItemIcon>
            <FaUsers className='nav-icon' />
          </ListItemIcon>
          <ListItemText>Users</ListItemText>
          {!open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </ListItem>
        <Collapse in={open}>
          <List component='div' disablePadding>
            <ListItem
              button
              component={Link}
              to={routes.admins}
              className='nested'
            >
              <ListItemIcon>
                <FaUsersCog className='nav-icon' />
              </ListItemIcon>
              <ListItemText>Admin Users</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          component={Link}
          to={routes.serviceProviders}
          button
          className='nav-item'
        >
          <ListItemIcon>
            <FiSettings className='nav-icon' />
          </ListItemIcon>
          <ListItemText>Service Providers</ListItemText>
        </ListItem>
        <ListItem
          component={Link}
          to={routes.services}
          button
          className='nav-item'
        >
          <ListItemIcon>
            <MdMap className='nav-icon' />
          </ListItemIcon>
          <ListItemText>Services</ListItemText>
        </ListItem>
        <ListItem
          component={Link}
          to={routes.categories}
          onClick={() => setOpen(true)}
          button
          className='nav-item'
        >
          <ListItemIcon>
            <Category className='nav-icon' />
          </ListItemIcon>
          <ListItemText>Categories</ListItemText>
          {!open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </ListItem>
        <Collapse in={open}>
          <List component='div' disablePadding>
            <ListItem
              button
              component={Link}
              to={routes.subCategories}
              className='nested'
            >
              <ListItemIcon>
                <CategoryTwoTone className='nav-icon' />
              </ListItemIcon>
              <ListItemText>Sub Categories</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          component={Link}
          to={routes.serviceRequests}
          button
          className='nav-item'
        >
          <ListItemIcon>
            <MdNotificationsActive className='nav-icon' />
          </ListItemIcon>
          <ListItemText>Requests</ListItemText>
        </ListItem>

        <ListItem
          component={Link}
          to={routes.countries}
          button
          className='nav-item'
        >
          <ListItemIcon>
            <MdNotificationsActive className='nav-icon' />
          </ListItemIcon>
          <ListItemText>Countries</ListItemText>
        </ListItem>
      </List>
    </div>
  );
}
