import { PureComponent } from 'react';
import { handleError } from '../../utils/errorHandler';

export default class DataLoading extends PureComponent {
  state = {
    loading: false,
    working: false,
    error: '',
  };

  toggleLoading = async loading => {
    await this.setState({
      loading,
    });
  };

  toggleWorking = async working => {
    await this.setState({
      working,
    });
  };
  handleError = err => {
    const error = handleError(err);
    this.setState({ error, loading: false, working: false });
  };
}
