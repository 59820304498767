import React from 'react'
import { Spinner } from 'react-bootstrap'
import classNames from 'classnames';
import './styles.css'

export default function Loader({className}) {
  return (
    <div className={classNames('loader', className)}>
      <Spinner animation="grow" />
    </div>
  )
}
