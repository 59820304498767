import axios from 'axios';
import { baseURL, uploadURL, mobileURL } from '../config';

export default class APIBase {
  constructor() {
    this.api = axios.create({
      baseURL,
      headers: {
        token: localStorage.getItem('token'),
      },
    });

    this.uploadAPI = axios.create({
      baseURL: uploadURL,
      headers: {
        token: localStorage.getItem('token'),
        'Content-Type':' multipart/form-data'
      },
    });
    this.mobileAPI = axios.create({
      baseURL: mobileURL,
      headers: {
        token: localStorage.getItem('token'),
      },
    });
  }
}
