import React from 'react';
import { Dropdown } from 'react-bootstrap';
import DropdownComponent from './Dropdown';
import classNames from 'classnames';
import {
  MdMoreVert,
  MdRemoveRedEye,
  MdDelete,
  MdEdit,
  MdAccessAlarm,
  MdCheck,
} from 'react-icons/md';
import {
  FaUserLock
} from 'react-icons/fa';
import './styles.css';

export default function ActionButtonComponent({
  onView,
  onDelete,
  onEdit,
  type,
  text,
  requestConfirmed,
  onConfirmRequest,
  onRejectRequest,
  isCategoryActive,
  onToggleCategory,
  onDeactivateUser
}) {
  let menu = (
    <DropdownComponent title={<MdMoreVert className='action-button' />}>
      <Dropdown.Item className='view' onClick={onView}>
        <MdRemoveRedEye /> View
      </Dropdown.Item>
    </DropdownComponent>
  );
  switch (type) {
    case 'country':
      menu = (
        <DropdownComponent title={<MdMoreVert className='action-button' />}>
          <Dropdown.Item className='view' onClick={onEdit}>
            <MdEdit /> Update Icon
          </Dropdown.Item>
        </DropdownComponent>
      );
      break;
    case 'admin':
      menu = (
        <DropdownComponent title={<MdMoreVert className='action-button' />}>
          <Dropdown.Item className='trash' onClick={onDeactivateUser}>
            <FaUserLock /> {text}
          </Dropdown.Item>
        </DropdownComponent>
      );
      break;
    case 'service':
      menu = (
        <DropdownComponent title={<MdMoreVert className='action-button' />}>
          <Dropdown.Item className='trash' onClick={onDelete}>
            <MdDelete /> Deactivate Service
          </Dropdown.Item>
        </DropdownComponent>
      );
      break;
    case 'category':
      menu = (
        <DropdownComponent title={<MdMoreVert className='action-button' />}>
          <Dropdown.Item
            className={classNames(isCategoryActive ? 'trash' : 'view')}
            onClick={onToggleCategory}
          >
            {isCategoryActive ? (
              <>
                <MdDelete /> Deactivate
              </>
            ) : (
              <>
                <MdCheck /> Activate
              </>
            )}
          </Dropdown.Item>
        </DropdownComponent>
      );
      break;
    case 'request':
      menu = !requestConfirmed && (
        <DropdownComponent title={<MdMoreVert className='action-button' />}>
          <Dropdown.Item className='view' onClick={onConfirmRequest}>
            <MdAccessAlarm /> Confirm Request
          </Dropdown.Item>
          <Dropdown.Item className='trash' onClick={onRejectRequest}>
            <MdDelete /> Reject Request
          </Dropdown.Item>
        </DropdownComponent>
      );
      break;

    default:
      break;
  }
  return menu;
}
