import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from '../../routes';

export default function RestrictedRoute({
  path,
  exact,
  Component,
  title = 'Dashboard',
  ...rest
}) {
  const user = localStorage.getItem('username');
  const userType = localStorage.getItem('userType');
  return (
    <Route
      path={path}
      exact={exact}
      {...rest}
      render={props => {
        if (!user) {
          return <Redirect to={routes.login} />;
        }
        if (
          userType.toUpperCase() !== 'ADMIN' &&
          userType.toUpperCase() !== 'STAFF'
        ) {
          localStorage.clear();
          return <Redirect to={routes.login} />;
        }
        document.title = `Kazi Portal ${title}`;
        return <Component {...props} />;
      }}
    />
  );
}
