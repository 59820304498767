import React, { lazy, Suspense, useState } from 'react';
import SideNav from './components/SideNav';
import classNames from 'classnames';
import Header from './components/Header';
import { Switch, Route } from 'react-router-dom';
import { routes } from './routes';
import Login from './components/Auth/login';
import RestrictedRoute from './components/RestrictedRoute';
import Loader from './components/common/Loader';
import ServiceRequests from './components/ServiceRequests';
import ServiceCategories from './components/ServiceCategories';
import SubCategories from './components/SubCategories';

const Country = lazy(() => import('./components/Country'));
const Services = lazy(() => import('./components/Services'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Users = lazy(() => import('./components/Users'));
const UserDetails = lazy(() =>
  import('./components/Users/components/UserDetails')
);
const ServiceProviders = lazy(() => import('./components/ServiceProviders'));
const AdminUsers = lazy(() =>
  import('./components/Users/components/AdminUsers')
);

function App() {
  const [collapse, onCollapse] = useState(false);

  return (
    <Suspense fallback={<Loader className='loader-alt' />}>
      <Switch>
        <Route exact path={routes.login} component={Login} />
        <>
          <div className='main'>
            <SideNav onCollapse={onCollapse} collapse={collapse} />
            <div
              className={classNames(
                collapse ? 'main-content__expand' : 'main-content'
              )}
            >
              <Header onCollapse={onCollapse} collapse={collapse} />
              <div className='content-wrapper'>
                <Switch>
                  <RestrictedRoute
                    exact={true}
                    path={routes.dashboard}
                    Component={Dashboard}
                  />
                  <RestrictedRoute
                    exact={true}
                    path={routes.users}
                    Component={Users}
                  />
                  <RestrictedRoute
                    exact={true}
                    path={routes.admins}
                    Component={AdminUsers}
                  />
                  <RestrictedRoute
                    exact={true}
                    path={routes.userDetails}
                    Component={UserDetails}
                  />
                  <RestrictedRoute
                    exact={true}
                    path={routes.services}
                    Component={Services}
                  />
                  <RestrictedRoute
                    exact={true}
                    path={routes.serviceRequests}
                    Component={ServiceRequests}
                  />
                  <RestrictedRoute
                    exact={true}
                    path={routes.serviceProviders}
                    Component={ServiceProviders}
                  />
                  <RestrictedRoute
                    exact={true}
                    path={routes.categories}
                    Component={ServiceCategories}
                  />
                  <RestrictedRoute
                    exact={true}
                    path={routes.subCategories}
                    Component={SubCategories}
                  />
                  <RestrictedRoute
                    exact={true}
                    path={routes.countries}
                    Component={Country}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </>
      </Switch>
    </Suspense>
  );
}

export default App;
