import React, { useState } from 'react';
import { Form, FormControl, Row, Col } from 'react-bootstrap';
import '../styles.css';
import SpinnerButton from '../../common/SpinnerButton';
import { handleError } from '../../../utils/errorHandler';
import ErrorMessage from '../../common/ErrorMessage';
import { setUser } from '../../../utils/authHelpers';
import { history } from '../../../utils/history';
import AuthAPI from '../../../api/auth';
import { routes } from '../../../routes';

export default function Login() {
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const onChange = ({ target }) => {
    const { name, value } = target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    // reset error
    setError('');
    const { username, password } = loginData;
    if (!username || !password) {
      setError(handleError('Username and Password must be provided!'));
    }
    try {
      setSubmitting(true);
      const { error, payload } = await new AuthAPI().login({
        password,
        username,
      });
      if (error) {
        setSubmitting(false);
        setError(handleError(error));
        return;
      }
      const {token, user}= payload
      if(user.status.toUpperCase() === 'ACCOUNT_DISABLED'){
        setSubmitting(false);
        return setError(handleError('Your account is inactive, please contant the administrator'));
      }

      setUser({
        token,
        username: user.username,
        userType: user.user_type
      });
      setSubmitting(false);
      history.push(routes.dashboard);
    } catch (error) {
      setSubmitting(false);
      setError(handleError(error));
    }
  };
  return (
    <div className='login'>
      <Row className='login__form'>
        <Col md={5} className='login-info'>
          <h4> Welcome!</h4>
          <p>Please key in your username and password to access the dashboard</p>
        </Col>
        <Col md={7} className='login-form'>
          {error && <ErrorMessage message={error} />}
          <Form onSubmit={onSubmit}>
            <Form.Group as={Col}>
              <Form.Label>Username</Form.Label>
              <FormControl
                name='username'
                value={loginData.username}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Password</Form.Label>
              <FormControl
                type='password'
                name='password'
                value={loginData.password}
                onChange={onChange}
              />
            </Form.Group>
            <div className='login-btn-container'>
              <Form.Group as={Col}>
                <Row>
                  <Col md={6}>
                    <SpinnerButton
                      type='submit'
                      disabled={submitting}
                      working={submitting}
                      className='btn-primary'
                    >
                      Login
                    </SpinnerButton>
                  </Col>
                  <Col md={6} className='forgot-password'>
                    Forgot Password?
                  </Col>
                </Row>
              </Form.Group>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
