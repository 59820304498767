import React from 'react';
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import './styles.css';
import { Nav } from 'react-bootstrap';

export default function DropdownComponent({ title, showCaret = false, children }) {
  return (
    <Nav>
      <UncontrolledDropdown direction='down' className="dropdown-container">
        <DropdownToggle caret={showCaret} nav className='dropdown-title'>{title}</DropdownToggle>
        <DropdownMenu right className='dropdown-menu'>{children}</DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
}
