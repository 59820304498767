export const setUser = ({ token, username, userType }) => {
  setItems({
    token,
    username,
    userType,
  });
};
const setItems = obj => {
  Object.keys(obj).forEach(key => localStorage.setItem(key, obj[key]));
};
